let headerLogo = document.getElementsByClassName("navheader__img")[0];
let myOffCanvas = document.getElementById("offcanvasSidebar");
let bsOffCanvas = new bootstrap.Offcanvas(myOffCanvas);

document
  .getElementById("offCanvasToggle")
  .addEventListener("click", function (e) {
    e.preventDefault();
    e.stopPropagation();
    bsOffCanvas.toggle();
  });

document.querySelectorAll(".side-link").forEach((item) => {
  item.addEventListener("click", (e) => {
    setTimeout(bsOffCanvas.toggle(), 100);
  });
});

myOffCanvas.addEventListener("shown.bs.offcanvas", function () {
  headerLogo.classList.add("hidden");
});

myOffCanvas.addEventListener("hidden.bs.offcanvas", function () {
  headerLogo.classList.remove("hidden");
});

function adeusAsViuvas(selector, numWords) {
  // Get array of all the selected elements
  var elems = document.querySelectorAll(selector);
  var i;
  for (i = 0; i < elems.length; ++i) {
    // Split the text content of each element into an array
    var textArray = elems[i].innerText.split(" ");

    // Remove the last n words and join them with a none breaking space
    var lastWords = textArray.splice(-numWords, numWords).join("&nbsp;");

    // Join it all back together and replace the existing
    // text with the new text
    var textMinusLastWords = textArray.join(" ");
    elems[i].innerHTML = textMinusLastWords + " " + lastWords;
  }
}

// Goodbye lonely words
adeusAsViuvas("p", 3);

// Logic for each page

let pathName = window.location.pathname.toString();

// Home page
if (
  pathName === "/" ||
  pathName === "/index.html" ||
  pathName === "/aog-gulp/"
) {
  function fetchPosts() {
    fetch(
      "https://blogger.googleapis.com/v3/blogs/1341721834002824333/posts?fetchImages=true&maxResults=4&orderBy=PUBLISHED&key=AIzaSyAYryflX4FsYRy9TR4-4Sr34egvRsb7ZCU"
    )
      .then((r) => r.json())
      .then((data) => {
        // console.log(data);

        if (!data.items) {
          console.log("nenhum post");
          return;
        }

        // Post 1
        if (data.items.length > 0) {
          document.getElementById("p1-img").style.background =
            "linear-gradient(to bottom, transparent 0%, black 100%), url(" +
            data.items[0].images[0].url +
            ")";

          document.getElementById("p1-img").onmouseover = function() {
            document.getElementById("p1-img").style.background =
            "linear-gradient(to bottom, transparent -100%, black 100%), url(" +
            data.items[0].images[0].url +
            ")";
          }

          document.getElementById("p1-img").onmouseleave = function() {
            document.getElementById("p1-img").style.background =
            "linear-gradient(to bottom, transparent 0%, black 100%), url(" +
            data.items[0].images[0].url +
            ")";
          }
          
          document.getElementById("p1-title").innerText = data.items[0].title;
          document.getElementById("p1-link").href = data.items[0].url;
        }

        // Post 2
        if (data.items.length > 1) {
          document.getElementById("p2-meta").innerText =
            new Date(data.items[1].published).toLocaleDateString("pt-br") +
            " - " +
            data.items[1].author.displayName;
          document.getElementById("p2-title").innerText = data.items[1].title;
          document.getElementById("p2-title").href = data.items[1].url;
        }

        // Post 3
        if (data.items.length > 2) {
          document.getElementById("p3-meta").innerText =
            new Date(data.items[2].published).toLocaleDateString("pt-br") +
            " - " +
            data.items[2].author.displayName;
          document.getElementById("p3-title").innerText = data.items[2].title;
          document.getElementById("p3-title").href = data.items[2].url;
        }

        // Post 4
        if (data.items.length > 3) {
          document.getElementById("p4-meta").innerText =
            new Date(data.items[3].published).toLocaleDateString("pt-br") +
            " - " +
            data.items[3].author.displayName;
          document.getElementById("p4-title").innerText = data.items[3].title;
          document.getElementById("p4-title").href = data.items[3].url;
        }
      });
  }
  fetchPosts();
}

// Contact page

if (window.location.href.indexOf("/contato") > -1) {
  const masks = {
    phone(value) {
      return value
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3")
        .replace(/(-\d{4})\d+?$/, "$1");
    },
  };

  document.querySelectorAll("#phone").forEach(($input) => {
    const field = $input.dataset.js;

    $input.addEventListener(
      "input",
      (e) => {
        e.target.value = masks[field](e.target.value);
      },
      false
    );
  });
}
